<template>
  <div class="req-temp-container mt-6">
    <vs-card class="mt-6 merchantDetail">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="text-lg md:text-xl lg:text-2xl font-medium text-dark-blue">{{ merchantDetail.merchant.name }}</h3>
        </div>
        <p class="mt-2 text-base">
          <label class="font-light mr-2 text-dark-blue">Merchant ID :</label>
          {{ merchantDetail.merchant.merchantId }}
        </p>
      </div>
      <div class="merchantContent">
        <div class="merchantContentBlock">
          <div class="header">
            <img :src="edit" alt="">
            <h3 class="lg:text-xl text-lg">Primary contact person</h3>

          </div>
          <hr />
          <div class="body">
            <div class="flex flex-wrap">
              <div class="input-group">
                <label class="text-base text-black font-normal mb-2 block">{{ merchantDetail.primaryContact.name }}</label>
                <p class="text-base leading-normal">
                  <span class="block mb-2">+61 {{ merchantDetail.primaryContact.phone }}</span
                  >
                  <a :href="'mailto:' + merchantDetail.primaryContact.email">{{
                    merchantDetail.primaryContact.email
                  }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="merchantContentBlock">
          <div class="header">
            <img :src="bank" alt="">
            <h3 class="lg:text-xl text-lg">Bank accounts</h3>
          </div>
          <hr />
          <div class="body">
            <div class="product-grid">
              <div v-for="bank in merchantDetail.banks" :key="bank._id">
                <div class="mb-4">
                  <h6 class="text-base font-normal mb-2">Account display name</h6>
                  <p class="text-base">{{ bank.displayName }}</p>
                </div>

                <div>
                  <h6 class="text-base font-normal mb-2">Account ID</h6>
                  <p class="text-base">{{ bank.bankAccountId }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="merchantContentBlock">
          <div class="header">
            <img :src="clipboard" alt="">
            <h3 class="lg:text-xl text-lg">Templates</h3>
          </div>
          <hr />
          <div class="body">
            <div class="product-grid">
              <div v-for="temp in merchantDetail.templates" :key="temp._id">
                <h6 class="text-base font-normal mb-4">{{ temp.templateName }}</h6>
                <div class="my-4">
                  <h6 class="text-base font-light mb-4">Template ID</h6>
                  <p v-if="temp.requestTemplateId" class="text-base">
                    {{ temp.requestTemplateId }}
                  </p>
                  <p v-else class="text-base">-</p>
                </div>

                <div>
                  <h6 class="font-light text-base mb-4">Template type</h6>
                  <p class="text-base mt-1 capitalize">
                    {{ temp.templateType }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="merchantContentBlock">
          <div class="header">
            <img :src="briefcase" alt="">
            <h3 class="lg:text-xl text-lg">Products</h3>
          </div>
          <hr />
          <div class="body">
            <div class="product-grid">
              <div
                v-for="product in merchantDetail.products"
                :key="product._id"
              >
                <h6 class="text-base font-normal">{{ product.productName }}</h6>
                <div class="mt-4">
                  <h5 class="text-base font-normal mb-4">Product ID</h5>
                  <p class="text-base">{{ product.customPlanId }}</p>
                </div>

                <div class="mt-6">
                  <vs-button
                    @click="gotoProductyDetail(product._id)"
                    style="max-width: 130px !important"
                    class="only-border-btn flex items-center justify-center"
                    v-round
                    size="small"
                    >View</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="merchantContentBlock">
          <div class="header">
            <img :src="folder" alt="">
            <h3 class="lg:text-xl text-lg">Logos</h3>
          </div>
          <hr />
          <div class="body">
            <div class="logos-grid">
              <div v-for="logo in merchantDetail.logos" :key="logo.logoId">
                <div class="logo-border">
                  <S3ImageGenerator :document="logo.logoImage" :key="logo.logoImage"/>
                </div>
                <div>
                  <h6 class="font-normal text-base mb-2">Logo ID</h6>
                  <p class="font-normal text-base">{{ logo.logoId }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
const edit=require("@/assets/images/edit.png");
const bank=require("@/assets/images/bank.png");
const folder=require("@/assets/images/folder.png");
const briefcase=require("@/assets/images/briefcase.png");
const clipboard=require("@/assets/images/clipboard.png");
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

export default {
  components: {
    S3ImageGenerator
  },
  data() {
    return {
      edit:edit,
      bank:bank,
      folder:folder,
      briefcase:briefcase,
      clipboard:clipboard,
      merchantDetail: {
        merchant: {
          name: "",
          id: "",
        },
        primaryContact: {
          name: "",
          phone: "",
          email: "",
        },
        banks: {},
        templates: {},
        products: {},
        logos: {},
      },
    };
  },
  methods: {
    ...mapActions("merchant", ["fetchMerchantDetail"]),

    async get() {
      this.fetchMerchantDetail(this.merchantId)
        .then((response) => {
          //  this.merchantDetail = response.data.data;
          this.merchantDetail.merchant = {
            name: response.data.data.accountName,
            merchantId: response.data.data.merchantId,
          };
          this.merchantDetail.primaryContact = {
            name: response.data.data.accountName,
            phone: response.data.data.phoneNumber,
            email: response.data.data.email,
          };
          this.merchantDetail.banks = response.data.data.bank;
          this.merchantDetail.templates = response.data.data.template;
          this.merchantDetail.products = response.data.data.payLaterPlans;
          this.merchantDetail.logos = response.data.data.logo;
        })
        .catch((err) => {

        });
    },
    gotoProductyDetail(pid) {
      this.$router.push({ name: "product-detail", params: { id: pid } });
    },
  },
  mounted() {
    this.get();
    this.merchantDetail.templates = this.merchantDetail.templates.filter(
      (item) => {
        return item.type == "template";
      }
    );
  },
  computed: {
    merchantId() {
      return this.$route.params.id;
    },
  },
};
</script>
